import $ from "jquery"
import { trackEvent as trackAnalyticsEvent } from "utilities/analytics"
import compact from "lodash/compact"
import { datadogRum } from "@datadog/browser-rum"

export const trackEvent = async (
  eventCategory: EventCategory,
  eventType: string,
  eventName?: string
): Promise<void> => {
  const analyticsParams = [eventCategory, eventType, eventName].filter(
    (arg) => arg
  ) as string[]

  const glassboxEventName = `${eventCategory}-${eventType}`
  datadogRum.addAction(eventType, {
    categoryName: eventCategory,
    glassboxEventName,
  })

  await trackAnalyticsEvent(analyticsParams)
}

export enum EventCategory {
  Activation = "Activation",
  AnySupplier = "AnySupplier",
  ClinicalFacilityDashboard = "ClinicalFacilityDashboard",
  ClinicalDashboardSidebar = "ClinicalDashboardSidebar",
  FacilityDashboardFocusTiles = "FacilityDashboardFocusTiles",
  FHIRIntegration = "FHIRIntegration",
  CustomerSupport = "CustomerSupport",
  FulfillmentStatusModal = "FulfillmentStatusModal",
  GeneralAnalytics = "General Analytics",
  InOrderActions = "InOrderActions",
  IncomingOrders = "IncomingOrders",
  Invitations = "Invitations",
  OrderSubmissionWarnings = "OrderSubmissionWarnings",
  PreferredSupplier = "PreferredSupplier",
  ProductConfiguration = "ProductConfiguration",
  PushbackUserSelection = "PushbackUserSelection",
  Resupply = "Resupply",
  ResupplySignUp = "ResupplySignUp",
  ReviewerSidebarSelfSignUp = "ReviewerSidebarSelfSignUp",
  ShareRequestLink = "ShareRequestLink",
  SignableAccessCode = "SignableAccessCode",
  SigningClinician = "SigningClinician",
  SignOnScreen = "SignOnScreen",
  PushbackSignUp = "PushbackSignUp",
  UserFeedback = "UserFeedback",
  UtilityNav = "UtilityNav",
  TransferSalesRep = "TransferSalesRep",
  ReassignSalesRep = "ReassignSalesRep",
  FacilitySupportPage = "FacilitySupportPage",
  SupplierGeneratedOrders = "SupplierGeneratedOrders",
  SupplierSelection = "SupplierSelection",
  FacilityFaxInput = "FacilityFaxInput",
  PatientPortalChat = "PatientPortalChat",
  FacilityCancellationWithReason = "FacilityCancellationWithReason",
  SalesCollaborationTools = "SalesCollaborationTools",

  // old, pre-exiting format, follow titleized format above
  // with enum and values being the same
  PaymentsPage = "payments-page",
  SupplierInfoCenterMethodSelector = "SupplierInfoCenter/AccountCreation/MethodSelector",
  SupplierTransfer = "Supplier Transfer",
  SsrApp = "ssr-app", // this is what the react-native code sends for SSR App
}

export const clickTrackingEventHandler = async (event) => {
  const { target } = event
  const closestContext = $(target).closest("[data-analytics-context]")
  const closestAnalyticsTag = $(target).closest("[data-analytics]")
  const eventCategory = closestContext[0]
    ? closestContext.data("analyticsContext")
    : EventCategory.GeneralAnalytics
  const [eventType, eventName] = closestAnalyticsTag
    .data("analytics")
    .split(":")

  if (!eventType || !eventCategory) {
    return
  }

  await trackAnalyticsEvent(compact([eventCategory, eventType, eventName]))
}
